import { useEffect } from "react";

export default function ScrollToTop({ location }) {
	
	const pathname = location.pathname;
	const hostname = location.hostname

	useEffect(() => {
		
		window.scrollTo(0, 0);

		const hamburger = document.querySelector(".hamburger");
		const menuContainer = document.querySelector("#menu");

		if ( hamburger ) {
			hamburger.classList.remove("is-active");
			menuContainer.classList.remove('open');
			menuContainer.style.height = '0px';		
		}

		const linkCLick = e => {

			e = e || window.event;
    		var clickTarget = e.target;
			
			var anchor = getParentAnchor(e.target);

			if (anchor !== null) {
				var url = anchor.href;
				var targetClicked = anchor.target;

				if (isURL(url)) e.preventDefault();

				var current_host = hostname;
				var to_host = current_host;
				if (isURL(url)) {
					var to_host = new URL(url).hostname;
				}

				if (targetClicked == undefined || targetClicked == '') targetClicked = "_self";
				if (isURL(url) && get_url_extension(url) == 'pdf') {
					window.open(url, '_blank');
				} else if ( (current_host != to_host) || (anchor.classList.contains('external_link')) || (anchor.classList.contains('external_button')) || (anchor.classList.contains('external')) ) {
					// Show warning message prior to sending to external site
					var warn = window.confirm('This link takes you away from the UVM Health Advantage website.');
					if(warn == true) {
						e.preventDefault();
						window.open(url,'_blank');
					} else {
						e.preventDefault();
						return;
					}
				} 
			}
		}; 

		
		document.body.addEventListener('click', linkCLick, false);

		return () => {
			document.body.removeEventListener('click', linkCLick, false);
		}


	}, [pathname]);

	// external links
	function isURL(str) {
		const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
			'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
		return !!pattern.test(str);
	}

	function get_url_extension( url ) {
		return url.split(/[#?]/)[0].split('.').pop().trim();
	}

	var getParentAnchor = function (element) {
		while (element !== null) {
		  if (element.tagName && element.tagName.toUpperCase() === "A") {
			return element;
		  }
		  element = element.parentNode;
		}
		return null;
	};

  return null;
}