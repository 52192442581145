import * as React from "react"
import { Link } from "gatsby"

//import classes from './Navigation.module.css';

const Main = () => {
	const hamburgerHandler = () => {
		const hamburger = document.querySelector(".hamburger");
		const menuContainer = document.querySelector("#menu");

		if ( hamburger ) {
			hamburger.classList.toggle("is-active");
			
			menuContainer.style.height = '0px';		
			menuContainer.addEventListener('transitionend', function () {
				menuContainer.classList.remove('open');
			}, {
				once: true
			});
		}
	}

	return (
		<nav role="navigation" aria-label="Primary" className="primarynav">
			<ul>
				<li><Link to="/about" activeClassName="current" partiallyActive={true} onClick={hamburgerHandler}>About</Link></li>
				<li><Link to="/plans" activeClassName="current" partiallyActive={true} onClick={hamburgerHandler}>Plans</Link></li>
				<li><Link to="/medicare-basics" activeClassName="current" partiallyActive={true} onClick={hamburgerHandler}>Medicare Basics</Link></li>
				<li><Link to="/meet-with-us" activeClassName="current" partiallyActive={true} onClick={hamburgerHandler}>Meet with Us</Link></li>
				<li><Link to="/enroll-now" activeClassName="current" partiallyActive={true} onClick={hamburgerHandler}>Enroll Now</Link></li>
				<li><Link to="/contact-us" activeClassName="current" partiallyActive={true} onClick={hamburgerHandler}>Contact Us</Link></li>
			</ul>
		</nav>
	)
}

export default Main;
