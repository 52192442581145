
import * as React from "react"

const Hamburger = () => {
	const hamburgerHandler = () => {
		const hamburger = document.querySelector(".hamburger");
		const menuContainer = document.querySelector("#menu");

		hamburger.classList.toggle("is-active");
			
		if (!menuContainer.classList.contains('open')) {
			menuContainer.classList.add('open');
			menuContainer.style.height = 'auto';
		
			let height = menuContainer.clientHeight + 'px';
		
			menuContainer.style.height = '0px';
		
			setTimeout(function () {
				menuContainer.style.height = height;
			}, 0);
		} else {
			menuContainer.style.height = '0px';		
			menuContainer.addEventListener('transitionend', function () {
				menuContainer.classList.remove('open');
			}, {
				once: true
			});
		}
	}

	return (
		<button onClick={hamburgerHandler} className="hamburger hamburger--spring" type="button" aria-expanded="false" aria-controls="menu" aria-label="Expand Main Navigation">
			<span className="hamburger-box">
				<span className="hamburger-inner"></span>
			</span>
			<span className="label">Menu</span>
		</button>
	)
}

export default Hamburger;

