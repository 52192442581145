import * as React from "react"
import { Link } from "gatsby"
import Layout from "../templates/layout-standard"
import Seo from "../components/seo"
import "../assets/css/screen.css"
import requestkitprimaryctaimg15x from '../assets/img/request-kit-primary-cta-img1-5x.jpg';
import talkprimaryctaimg15x from '../assets/img/talk-primary-cta-img1-5x.jpg';
import ntmprimaryctaimg15x from '../assets/img/ntm-primary-cta-img1-5x.jpg';
// import plansprimaryctaimg15x from '../assets/img/plans-primary-cta-img1-5x.jpg';

const IndexPage = ({ location }) => (
	<Layout location={location} heading1={["Welcome to ", <span>UVM Health Advantage</span>]} heading2={["Created with You. ", <span>Guided by Doctors.</span>]}>
		
		<Seo title="Medicare Plans for NY &amp; VT" meta={[{description: 'UVM Health Advantage is a new kind of Medicare Advantage plan available across all of Vermont and in Clinton, Essex, Franklin, Hamilton, and St. Lawrence counties in New York.'}]} bodyclass="home" />
		<div>
			<Link to="/about/" className="stripe-cta">
				<div>
					<p className="arrow">Discover a <span className="heading-highlight">new</span> kind of Medicare Advantage Plan.</p>
					<p>UVM Health Advantage offers a valuable alternative to Original Medicare, including prescription coverage, extra benefits and more &ndash; all in one simple, affordable plan.</p>
				</div>
			</Link>
		</div>
		
		<main>
			<div className="ctas">
				<div className="constrained">
					<div className="flexwrap">
						<Link to="/meet-with-us" className="primary-cta">
							<div className="image">
								<img src={talkprimaryctaimg15x} alt="" width="768" height="374" loading="lazy" />
								<div><h3>Let&rsquo;s Talk</h3></div>
							</div>
							<p>In person, by phone or videoconference, our Plan Guides will&nbsp;help you find the right plan and make sure your transition goes smoothly.</p>
							<p className="button">Schedule a Meeting</p>
						</Link>
						<Link to="/request-a-kit" className="primary-cta">
							<div className="image">
								<img src={requestkitprimaryctaimg15x} alt="" width="768" height="374" loading="lazy" />
								<div><h3>Request a Kit</h3></div>
							</div>
							<p>Get benefit details, compare plans and more when you request your FREE UVM Health Advantage Plan Benefit Kit.</p>
							<p className="button">Get the Kit</p>
						</Link>
						<Link to="/medicare-basics" className="primary-cta">
							<div className="image">
								<img src={ntmprimaryctaimg15x} alt="" width="768" height="374" loading="lazy" />
								<div><h3>New to Medicare?</h3></div>
							</div>
							<p>Find helpful tips, information and timelines.</p>
							<p className="button">Get Started</p>
						</Link>

						{/* <Link to="/request-a-kit" className="primary-cta">
							<div className="image">
								<img src={requestkitprimaryctaimg15x} alt="" width="768" height="374" loading="lazy" />
								<div><h3>Request a Kit</h3></div>
							</div>
							<p>Get benefit details, compare plans and more when you request your FREE UVM Health Advantage Plan Benefit Kit.</p>
							<p className="button">Get the Kit</p>
						</Link>
						<Link to="/plans/uvm-health-advantage" className="primary-cta">
							<div className="image">
								<img src={plansprimaryctaimg15x} alt="" width="768" height="374" loading="lazy" />
								<div><h3>See Our Plans</h3></div>
							</div>
							<p>Compare benefits and estimate costs now.</p>
							<p className="button">Get Started</p>
						</Link>
						<Link to="/meet-with-us" className="primary-cta">
							<div className="image">
								<img src={talkprimaryctaimg15x} alt="" width="768" height="374" loading="lazy" />
								<div><h3>Let&rsquo;s Talk</h3></div>
							</div>
							<p>In person, by phone or videoconference, our Plan Guides will&nbsp;help you find the right plan and make sure your transition goes smoothly.</p>
							<p className="button">Schedule a Meeting</p>
						</Link> */}
						
					</div>
				</div>		
			</div>
		</main>
	</Layout>
)

export default IndexPage
