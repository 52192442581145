import * as React from 'react'
import { Link } from 'gatsby';
import Hamburger from './ui/Hamburger'
import Main from './nav/Main'
import Secondary from './nav/Secondary'
import uvmmvplogo from '../assets/img/uvm-mvp-logo.svg'
import "../assets/css/screen.css"

const Topper = () => {
	return (
		<div className="topper">
			{/* <div className="notification-banner">
				<div className="constrained">
					<p>Medicare Annual Enrollment ends December 7th. We&rsquo;re here to help! <strong>Call 1-833-368-4592</strong> (TTY 711).</p>
				</div>
			</div> */}
			<div className="constrained">
				<div className="logo">
					<Link to="/"><img src={uvmmvplogo} alt="UVM Health &amp; MVP Health Care" width={250} height={49} /></Link>
				</div>
				
				<Hamburger />

				<div className="flexwrap_nav" id="menu">
					<div className="flexwrap_search">
						{ /* <Search /> */ }
						<Secondary />					
					</div>

					<Main />					
				</div>
			</div>
		</div>
	)
}

export default Topper;