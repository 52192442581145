import * as React from "react"
import { Link } from "gatsby"

const Secondary = () => {
	return (
		<nav aria-label="Secondary" className="secondarynav">
			<ul>
				<li key={1}><Link to="/find-a-doctor/" activeclassName="current">Find a Doctor</Link></li>
				<li key={2}><Link to="/rx-information/" activeclassName="current">Rx Information</Link></li>
				<li key={3}><Link to="/already-an-mvp-member/" activeclassName="current">Already a Member?</Link></li>
			</ul>
		</nav>
	)
}

export default Secondary;